<template>
    <section class="ver-cotizacion-realizadas container-fluid">
        <div class="d-middle cr-pointer icon-option" @click="$emit('goBack')">
            <i class="icon-arrow-left f-18" />
            <p class="f-15">Regresar cotizaciones realizadas</p>
        </div>
        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :usarServidor="true" :usarPaginacion="true" :servidorData="materialesCotizaciones" @paginar="listarPag">
            <el-table-column label="Referencia" prop="referencia" align="center" width="120">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 cr-pointer">
                        {{ scope.row.referencia }}
                    </p>
                </template>
            </el-table-column>
             <el-table-column label="Materiales" prop="materiales">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 tres-puntos">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="U.M" prop="um" width="120" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.configuracion_unidad_medidas.unidad_medida }} ({{ scope.row.configuracion_unidad_medidas.sigla }})
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cantidad" prop="cantMateriales" width="80" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.cantidad }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor Unitario" prop="valorUnitario" align="center" width="150">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatMoney(scope.row) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor total" prop="valor" width="150" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneyTotal(scope.row) }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            select_monedas: 'selects/selects/select_monedas',
            materialesCotizaciones: 'users_providers/usersProviders/materialesCotizaciones',
        }),
    },
    methods: {
        ...mapActions({
            Action_list_materiales_cotizaciones: 'users_providers/usersProviders/Action_list_materiales_cotizaciones',
        }),
        async listarPag(pag){
            await this.Action_list_materiales_cotizaciones({ id: this.materialesCotizaciones.data[0].id_cotizacion, payload: { page: pag }, cotizacion: 'pendientes' });
        },
        formatMoney(e) {
            return `${this.select_monedas.find(el => el.id === e.id_moneda)?.sigla} $${e.valor}`
        },
        formatoMoneyTotal(e) {
            return `${this.select_monedas.find(el => el.id === e.id_moneda)?.sigla} $${e.valor * Number(e.cantidad)}`
        }
    },
}
</script>

<style lang="scss" scoped>
.ver-cotizacion-realizadas{
    background: white;
}
</style>