<template>
    <section class="cotizaciones-realizadas mt-4">
        <template v-if="!showMaterials">
            <div class="row justify-content-end">
                <div class="col-auto">
                    <div v-if="contar == 0" class="col-auto my-auto px-1 icon-option">
                        <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                            <button class="btn btn-square32-5d" @click="abrirModalFiltros">
                                <i class="icon-filter text-white" />
                            </button>
                        </el-tooltip>
                    </div>
                    <div v-if="contar > 0" class="col-auto filtro-contador my-auto px-1">
                        <div class="d-flex border-general br-4">
                            <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                                <i class="icon-filter text-white" />
                            </button>
                            <div class="d-middle px-2">
                                <p class="f-12"> {{ contar }} </p>
                                <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="clearAllFilters" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <tabla-general :mostrarBuscador="false" alturaTabla="500px" :usarServidor="true" :usarPaginacion="true" :servidorData="cotizacionesRealizadas" @paginar="listarPag">
                <el-table-column label="Cotización" prop="numero" align="center">
                    <template slot-scope="scope">
                        <p class="f-12 text-muted2 f-600 cr-pointer" @click="verMateriales(scope.row)">
                            {{ scope.row.referencia }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="Fecha cotización" prop="fechaCotizacion" align="center">
                    <template slot-scope="scope">
                        <p class="f-12 text-muted2 tres-puntos">
                            {{ scope.row.fecha_cotizacion | helper-fecha('DD MMM YYYY') }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="Cantidad materiales" prop="cantMateriales" align="center">
                    <template slot-scope="scope">
                        <p class="f-12 text-muted2">
                            {{ scope.row.cantidad_materiales }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="Valor total" prop="valor" align="center">
                    <template slot-scope="scope">
                        <p class="f-12 text-muted2">
                            {{ formatoMoneda(scope.row.valor_total) }}
                        </p>
                    </template>
                </el-table-column>
            </tabla-general>
        </template>
        <ver-cotizacion-realizadas v-if="showMaterials" @goBack="goBack" />
        <!-- partials -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-3 justify-content-center my-2">
                <div class="col-12 mb-3">
                    <p class="input-label-top">Cotización</p>
                    <el-select v-model="filter.referencia" clearable placeholder="Seleccionar cotización" size="small" class="w-100">
                        <el-option v-for="item in select_cotizaciones" :key="item.id" :label="item.referencia" :value="item.referencia" />
                    </el-select>
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Fecha de inicio</p>
                    <el-date-picker 
                        v-model="filter.fecha_cotizacion_inicio"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="Seleccionar fecha"
                        size="small"
                        class="w-100"
                    />
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Fecha final</p>
                    <el-date-picker 
                        v-model="filter.fecha_cotizacion_fin"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="Seleccionar fecha"
                        size="small"
                        class="w-100"
                    />
                </div>
                <div class="col-12 mb-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="range_value" 
                        range 
                        :min="Number(rangeValueMaterials.valor_minimo)"
                        :max="Number(rangeValueMaterials.valor_maximo)"
                        :format-tooltip="formatTooltipRange"
                    />
                </div>
               
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearAllFilters"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="doFilter"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import verCotizacionRealizadas from './verCotizacionRealizadas.vue'
export default {
    components: {
        verCotizacionRealizadas,
    },
    data() {
        return {
            showMaterials: false
        }
    },
    async created () {
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['proveedores.usuarios']);
        await this.listar()
        this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs',[
            {
                position: 2,
                breadcumb: {
                    name: this.informationProvider.nombre,
                    route: 'proveedores.usuarios.cotizaciones.realizadas',
                }
            }
        ])
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            informationProvider: 'users_providers/usersProviders/informationProvider',
            select_cotizaciones: 'selects/selects/select_cotizaciones',
            rangeValueMaterials: 'users_providers/usersProviders/rangeValueMaterials',
            cotizacionesRealizadas: 'users_providers/usersProviders/cotizacionesRealizadas',
            filterRealizadas: 'users_providers/usersProviders/filterRealizadas',
        }),
        filter:{
            get(){ return this.filterRealizadas },
            set(val){ this.set_filters_realizadas(val) }
        },
        range_value:{
            get(){ return [this.filter.valor_minimo, this.filter.valor_maximo] },
            set(val){ this.filter.valor_minimo = val[0], this.filter.valor_maximo = val[1] },
        },
        contar(){
            return this.filter.count()
        },
    },
    methods: {
        ...mapMutations({
            set_info_top: 'users_providers/usersProviders/set_info_top',
            set_filters_realizadas: 'users_providers/usersProviders/set_filters_realizadas',
        }),
        ...mapActions({
            Action_get_select_cotizaciones: 'selects/selects/Action_get_select_cotizaciones',
            Action_get_value_materials: 'users_providers/usersProviders/Action_get_value_materials',
            Action_list_cotizaciones: 'users_providers/usersProviders/Action_list_cotizaciones_realizadas',
            Action_get_cantidad_value_ver_materials: 'users_providers/usersProviders/Action_get_cantidad_value_ver_materials',
            Action_list_materiales_cotizaciones: 'users_providers/usersProviders/Action_list_materiales_cotizaciones',
            clear_filter_materiales: 'users_providers/usersProviders/clean_filters_ver_pendientes',
            clear_filter: 'users_providers/usersProviders/clean_filters_realizadas',
        }),
        async listar(){
            await Promise.all([
                this.Action_get_select_cotizaciones({id_user: this.user.id, tipo: 2}),
                this.Action_get_value_materials({id_user: this.user.id}),
            ])
            this.clear_filter()
            await this.Action_list_cotizaciones(),
            this.set_info_top({})
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async doFilter(){
            await this.Action_list_cotizaciones();
            this.$refs.abrirModalFiltros.toggle()
        },
        async verMateriales(cotizacion){
            this.showMaterials = !this.showMaterials
            this.set_info_top(cotizacion)
            await this.Action_get_cantidad_value_ver_materials({id_cotizacion: cotizacion.id})
            this.clear_filter_materiales()
            await this.Action_list_materiales_cotizaciones({id: cotizacion.id, payload: {page: '1'}, cotizacion: 'realizadas'});
        },
        async clearAllFilters(){
            this.clear_filter()
            await this.Action_list_cotizaciones();
        },
        goBack(){
            this.showMaterials = !this.showMaterials
            this.set_info_top({})
        },
        async listarPag(pag){
            await this.Action_list_cotizaciones({page: pag});
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
    },
}
</script>

<style lang="scss" scoped>
.cotizaciones-realizadas{
    background: white;
}
</style>